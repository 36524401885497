<template>
    <div class="login-main">
        <h4 class="brand-info__text">示例平台1</h4>
        <div class="text-home">
        <img
            style="zoom:0.06"
            src="../../../public/img/logo.png"
        />
        心狗健康
        </div>
        <h3 class="login-title">欢迎登录</h3>
        <el-form
        :model="dataForm"
        :rules="dataRule"
        ref="dataForm"
        @keyup.enter.native="dataFormSubmit()"
        status-icon
        >
        <el-form-item prop="userName">
            <!-- <p>地区：</p> -->
            <el-input
            v-model="dataForm.areaName"
            placeholder="地区名"
            size="medium"
            ></el-input>
        </el-form-item>
        <el-form-item prop="password">
            <!-- <p>密码：</p> -->
            <el-input
            v-model="dataForm.password"
            type="password"
            placeholder="密码"
            size="medium"
            ></el-input>
        </el-form-item>
        <el-form-item prop="captcha">
            <el-row :gutter="20">
            <el-col :span="14">
                <el-input v-model="dataForm.captcha" placeholder="验证码" size="medium">
                </el-input>
            </el-col>
            <el-col :span="10" class="login-captcha">
                <img :src="captchaPath" @click="getCaptcha()" alt="" />
            </el-col>
            </el-row>
        </el-form-item>
        <el-form-item prop="radio">
            <el-radio-group v-model="dataForm.radio">
            <el-radio :label="'login'">政府监管</el-radio>
            <!-- <el-radio :label="'doctor'">签约医生</el-radio><br/> -->
            <el-radio :label="'admin'">后台管理</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item>
            <el-button
            class="login-btn-submit"
            type="primary"
            @click="dataFormSubmit()"
            >登录</el-button
            >
        </el-form-item>
        <el-form-item>
            <el-button
            class="regist-btn-submit"
            type="primary"
            @click="gotoRegist()"
            >还没有账号？马上注册</el-button
            >
        </el-form-item>
        <!-- <el-form-item>
            <el-link :href="url" type="primary" target="_blank">忘记密码？</el-link>
        </el-form-item> -->
        </el-form>
    </div>
</template>

<script>
import { getUUID } from '@/utils'
// import '../../assets/theme/el-input/theme/index.css'
export default {
  data () {
    return {
      url: 'url',
      dataForm: {
        areaName: '',
        password: '',
        uuid: '',
        captcha: '',
        radio: 'login'
      },
      dataRule: {
        areaName: [
          { required: true, message: '地区不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        captcha: [
          { required: true, message: '验证码不能为空', trigger: 'blur' }
        ]
      },
      captchaPath: ''
      // allUrl: ['/sys/login', '/sys/doctor', '/sys/admin'],
      // url: '/sys/login'
    }
  },
  created () {
    this.getCaptcha()
  },
  methods: {
    // 提交表单
    dataFormSubmit () {
      // this.$store.state.common.isReLogin = true
      this.$router.replace({ name: 'example1' })
      // this.$refs['dataForm'].validate((valid) => {
      //   if (valid) {
      //     var path = '/sys/' + this.dataForm.radio   // 根据登录角色来定提交表单的地址
      //     console.log(path)
      //     this.$http({
      //       url: this.$http.adornUrl(path),
      //       method: 'post',
      //       data: this.$http.adornData({
      //         areaName: this.dataForm.areaName,
      //         password: this.dataForm.password,
      //         uuid: this.dataForm.uuid,
      //         captcha: this.dataForm.captcha
      //       })
      //     }).then(({ data }) => {
      //       if (data && data.code === 0) {
      //         console.log("看看登录时返回的东西")
      //         console.log(data)
      //         this.$cookie.set('token', data.token)  // 登录成功设置请求令牌
      //         this.$store.state.common.isReLogin = true
      //         this.$router.replace({ name: 'home' })
      //       } else {
      //         this.getCaptcha()
      //         this.$message.error(data.msg)
      //       }
      //     })
      //   }
      // })
    },
    // 获取验证码
    getCaptcha () {
      this.dataForm.uuid = getUUID()
      this.captchaPath = this.$http.adornUrl(
        `/captcha.jpg?uuid=${this.dataForm.uuid}`
      )
    },
    // 转到注册界面
    gotoRegist () {
      this.$router.replace({ name: 'regist' })
    }
  }
}
</script>

<style lang="scss">
  .brand-info__text {
    text-align: center;
    margin: 0 0 22px 0;
    font-size: 34px;
    font-weight: 500px;
    text-transform: uppercase;
    
  }
  .text-home {
  text-align: center;
  font-size: 30px;
  color: red;
  margin-bottom: 10px;
}
  .login-main {
    position: absolute;
    top: 50%;
    left: 50%;
    // padding: 150px 60px 180px;
    width: 500px;
    transform: translate(-50%, -50%); 
    // min-height: 100%;
    // background-color: #fff;
    // z-index: 9999;
  }
  .login-title {
    font-size: 24px;
  }
  .login-captcha {
    overflow: hidden;
    > img {
      width: 100%;
      cursor: pointer;
    }
  }
  .login-btn-submit {
    width: 100%;
    margin-top: 25px;
    font-size: 18px;
  }
  .regist-btn-submit {
    width: 100%;
    font-size: 18px;
    // margin-top: 8px;
  }
</style>
